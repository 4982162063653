import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseURL } from './config';

const Timer = () => {
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    const fetchTimeLeft = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/time-left`);
        setTimeLeft(response.data.timeLeft);
      } catch (error) {
        console.error('Error fetching time left:', error);
      }
    };

    fetchTimeLeft();

    const interval = setInterval(() => {
      setTimeLeft(prev => (prev > 0 ? prev - 1000 : 0)); // Decrement time left by 1 second
    }, 1000);

    return () => clearInterval(interval); // Clear interval when component unmounts
  }, []);

  const formatTimeLeft = (milliseconds) => {
    const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    const seconds = Math.floor((milliseconds / 1000) % 60);
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  return (
<>
      {formatTimeLeft(timeLeft)}
</>
  );
};

export default Timer;
