import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseURL } from './config';
import './App.css';
import Timer from './Timer'; // Import Timer component
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

if(process.env.NODE_ENV === 'production') disableReactDevTools()

const Modal = ({ isOpen, onClose, clickCount, onShare, pathway }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <span className="close-button" onClick={onClose}>&times;</span>
        <div className='modalPathway'>
        {pathway.map((page, index) => (
              <li key={index}>{page} ➡️ </li> 
        ))}
        </div>
        <h2>Congratulations!</h2>   
        <p>You've successfully completed the scavenger hunt with just {clickCount} clicks! Great job!</p>
        <p>Why not challenge a friend and see who can finish faster?</p>
        <button onClick={onShare}>Share with Friends</button>
      </div>
    </div>
  );
};

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isRegister, setIsRegister] = useState(false);
  const [error, setError] = useState('');
  const [verificationAnswer, setVerificationAnswer] = useState('');
  const [verificationQuestion, setVerificationQuestion] = useState('');

  useEffect(() => {
    if (isRegister) {
      const num1 = Math.floor(Math.random() * 10);
      const num2 = Math.floor(Math.random() * 10);
      setVerificationQuestion(`What is ${num1} + ${num2}?`);
      setVerificationAnswer((num1 + num2).toString());
    }
  }, [isRegister]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    if (isRegister) {
      const userAnswer = e.target.verification.value;
      if (userAnswer !== verificationAnswer) {
        setError('Incorrect verification answer. Please try again.');
        return;
      }
    }
    try {
      const endpoint = isRegister ? '/api/register' : '/api/login';
      const response = await axios.post(`${baseURL}${endpoint}`, { 
        username, 
        password, 
        verification: isRegister ? e.target.verification.value : undefined 
      });
      onLogin(response.data.token, response.data.username);
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message || 'An error occurred during authentication');
      } else {
        setError('Network error. Please try again.');
      }
    }
  };

  return (
    <center>
    <div className='login-form-container'>
  <form className="login-form" onSubmit={handleSubmit}>
    <input
      type="text"
      placeholder="Username"
      value={username}
      onChange={(e) => setUsername(e.target.value)}
    />
    <input
      type="password"
      placeholder="Password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
    />
    {isRegister && (
      <div>
        <label>{verificationQuestion}</label>
        <input type="text"
          name="verification"
          placeholder="Enter your answer"
        />
        
      </div>

      
        
    )}

{error && <p>{error}</p>}
    <div className="button-container">
      <button type="submit">{isRegister ? 'Register' : 'Log In for more!'}</button>
      <a className="switchBTN" onClick={() => {setIsRegister(!isRegister); setError('');}}>
        {isRegister ? 'Login' : 'New? Sign up - and start hunting'}
      </a>
    </div>
  </form>

</div>
</center>

  );
};


const App = () => {
  const [article, setArticle] = useState({ title: '', htmlContent: '' });
  const [loading, setLoading] = useState(true);
  const [clickCount, setClickCount] = useState(0);
  const [maxClicks, setMaxClicks] = useState(100);
  const [targets, setTargets] = useState([]);
  const [completedTargets, setCompletedTargets] = useState([]);
  const [pathway, setPathway] = useState([]);
  const [username, setUsername] = useState('');
  const [huntCompleted, setHuntCompleted] = useState(false);
  const [view, setView] = useState('home');
  const [leaderboard, setLeaderboard] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState('');
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [tempUsername, setTempUsername] = useState('Guest'); // for anonymous user
  const [gameMode, setGameMode] = useState(''); // Add this state for game mode
  const [targetCount, setTargetCount] = useState(3); // Add this state for game mode
  const [showOptions, setShowOptions] = useState(false);
  const [elo, setElo] = useState(); // Default value
  const [dailyHunt, setDailyHunt] = useState([]); // Default value
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1100);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1100);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleLoginModal = () => {
    setLoginModalOpen(!isLoginModalOpen);
  };

  const closeLoginModal = () => {
    setLoginModalOpen(false);
  };

const renderLoginButton = () => {
  if (isMobileView && !isLoggedIn) {
    return (
      <>
      {gameMode === '' && (
      <button className="login-button" onClick={toggleLoginModal}>
        Login
      </button>
      )}
      </>
    );
  }
  return null;
};

const renderLoginModal = () => {
  if (isLoginModalOpen) {
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <div className="mobileX" onClick={closeLoginModal}>&times;</div>
          
          <Login onLogin={(token, username) => {
      setIsLoggedIn(true);
      setToken(token);
      setUsername(username);
      closeLoginModal();
      localStorage.setItem('authToken', token);
    }} /> 
        </div>
      </div>
    );
  }
  return null;
};

const handleShare = () => {
  if (navigator.share) {
    navigator.share({
      title: 'Scavenger Hunt Completed!',
      text: `I just completed the scavenger hunt with ${clickCount} clicks! Can you beat my score?`,
      url: window.location.href,
    }).then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
  } else {
    console.log('Web Share API not supported');
    // Fallback option: You could copy a message to clipboard here
  }
};

useEffect(() => {
  if (huntCompleted) {
    setIsModalOpen(true);
  }
}, [huntCompleted]);

// Add this at the beginning of the App component's return statement
const renderModal = () => (
  <Modal
    isOpen={isModalOpen}
    onClose={() => setIsModalOpen(false)}
    clickCount={clickCount}
    onShare={handleShare}
    pathway={pathway}
  />
);






useEffect(() => {
  const fetchUserDetails = async () => {
    const savedToken = localStorage.getItem('authToken');
    if (savedToken) {
      setToken(savedToken);
      try {
        const response = await axios.get(`${baseURL}/api/user`, {
          headers: { Authorization: `Bearer ${savedToken}` }
        });
        
        // Set username, login state, and ELO
        setUsername(response.data.username);
        setElo(response.data.elo); // Assuming your API returns the ELO
        setIsLoggedIn(true);
      } catch (error) {
        console.error('Error fetching user details:', error);
        // Handle token expiry or invalid token
      }
    }
  };

  fetchUserDetails();
}, []);
// Add dependencies if needed

useEffect(() => {
  
  const resetTargetsAndFetchData = async () => {
    await fetchInitialData(); // This fetches targets
  };

  if (gameMode !== '') {
    resetTargetsAndFetchData();
  }
  resetTargetsAndFetchData();}, [gameMode]); // Depend on gameMode


  const dailyHuntSet = async () => {
    const response = await axios.get(`${baseURL}/api/targets`); // Adjust the endpoint if needed
    setDailyHunt(response.data);

    
  };

  // Function to generate a random username
const generateRandomUsername = () => {
  return 'Guest_' + Math.random().toString(36).substring(2, 10);
};

  const fetchInitialData = async () => {
      
    try {

        // Fetch leaderboard automatically
      const leaderboardResponse = await axios.get(`${baseURL}/api/leaderboard`);
      setLeaderboard(leaderboardResponse.data);
      console.log(leaderboardResponse);
      
      const randomResponse = await axios.get(`${baseURL}/api/article/` + targets[0]);
      const randomTitle = randomResponse.data.title;

      setArticle({ title: randomTitle, htmlContent: '' });
      fetchArticle(randomTitle);
  
      setLoading(false);
    } catch (error) {
      console.error('Error fetching initial data', error);
      setLoading(false);
    }
  };

useEffect(() => {
  dailyHuntSet();
  if (article.title && targets.some(target => target.toLowerCase() === article.title.toLowerCase())) {
    if (!completedTargets.includes(article.title)) {
      setCompletedTargets(prev => [...prev, article.title]);
    }

    if (completedTargets.length === targets.length) {
      setHuntCompleted(true);
      saveClicks(); // Save clicks when hunt is completed
      setGameMode('');
    }
  }
}, [article, completedTargets, targets]);


  const handleLinkClick = (event) => {
    if (event.target.tagName === 'A' && event.target.href.includes('wikipedia.org')) {
      event.preventDefault();

      setClickCount(prevCount => prevCount + 1);
      const newArticleTitle = event.target.href.split('/wiki/')[1];
      setPathway(prevPathway => [...prevPathway, article.title]);
      fetchArticle(newArticleTitle);
    }
  };

  const fetchArticle = async (title) => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseURL}/api/article/${title}`);
      if (response.data && response.data.htmlContent) {
        setArticle(response.data);
      } else {
        setArticle({ title: '', htmlContent: '<p>Error loading article content</p>' });
      }
    } catch (error) {
      setArticle({ title: '', htmlContent: '<p>Error loading article content</p>' });
    }
    setLoading(false);
  };
  
const saveClicks = async () => {
  const userToSave = isLoggedIn ? username : generateRandomUsername();
  try {
    const headers = isLoggedIn ? { Authorization: `Bearer ${token}` } : {}; // Add token if logged in
    if(gameMode === "daily hunt"){
      await axios.post(`${baseURL}/api/saveClicks`, { username: userToSave, clicks: clickCount }, { headers });
    }
      await axios.post(`${baseURL}/api/updateScore`, { clicks: clickCount, numberOfDestinations: targetCount }, { headers }); // Update the score here
    
    
    if (isLoggedIn) {
      const response = await axios.get(`${baseURL}/api/user`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setElo(response.data.elo); // Update ELO after saving clicks
    }
	} catch (error) {
    console.error('Error saving clicks', error);
  }
};

  const showLeaderboard = async () => {
  setView('leaderboard');
  try {
    const headers = isLoggedIn ? { Authorization: `Bearer ${token}` } : {}; // Only send token if logged in
    const response = await axios.get(`${baseURL}/api/leaderboard`, { headers });
    setLeaderboard(response.data);
  } catch (error) {
    console.error('Error fetching leaderboard', error);
  }
};

  // Logout function
  const handleLogout = () => {
    setIsLoggedIn(false);
    setToken('');
    setGameMode('');
    setUsername('');
    localStorage.removeItem('authToken'); // Clear token from local storage
  };
  
  
  // Function to fetch targets based on button click
  const fetchTargets = async (numTargets) => {
    try {
      const response = await fetch(`${baseURL}/api/targets/${numTargets}`);
      const data = await response.json();

      setTargets(data);
      setShowOptions(false);
      setGameMode(numTargets); // Set game mode to daily hunt
	  setPathway([]); // Reset the pathway if you want
		setClickCount(0); // Reset click count if needed
    setCompletedTargets([]); // Reset completed targets if needed
    setTargetCount(numTargets);

    } catch (error) {
      console.error('Error fetching targets:', error);
    }
  };
 
  const ArticleImage = ({ target }) => {
    const [imageSource, setImageSource] = useState(null);
  
    useEffect(() => {
      const fetchImageSource = async () => {
        const src = await getImageSourceByArticleName(target);
        setImageSource(src);
      };
  
      fetchImageSource();
    }, []); // Re-run effect if `target` changes
  
    return (
      <div>
        {imageSource ? (
          <img src={imageSource} alt={target} />
        ) : (
          <p>Loading image...</p>
        )}
      </div>
    );
  };

const getImageSourceByArticleName = async (title) => {
  try {
    const response = await axios.get('https://en.wikipedia.org/w/api.php', {
      params: {
        action: 'query',
        titles: title,
        prop: 'pageimages',
        format: 'json',
        piprop: 'thumbnail',
        pithumbsize: 500, // Specify the desired size
        origin: '*', // Avoid CORS issues
      },
    });

    const pages = response.data.query.pages;
    const page = Object.values(pages)[0];

    if (page.thumbnail) {
      return page.thumbnail.source; // Return the image source URL
    } else {
      console.log('No image found for this article.');
      return null; // Return null if no image is found
    }
  } catch (error) {
    console.error('Error fetching article image:', error);
    return null; // Return null on error
  }
};


  
// Modify the resetTargets function to set the game mode
const resetTargets = async () => {
  try {
    const response = await axios.get(`${baseURL}/api/targets`); // Adjust the endpoint if needed
    setTargets(response.data);
    setDailyHunt(response.data);
    setGameMode('daily hunt'); // Set game mode to daily hunt
    setCompletedTargets([]); // Reset completed targets if needed
    setPathway([]); // Reset the pathway if you want
    setClickCount(0); // Reset click count if needed

  } catch (error) {
    console.error('Error fetching default targets:', error);
  }
};



  const showHome = () => {
    setView('home');
  };


    // Function to toggle the visibility
    const handleNewGameClick = () => {
      setShowOptions(!showOptions);
    };

    const handleAbortGameClick = () => {
      setGameMode('');
    };


return (
  <div className="mw-body">
    {renderLoginButton()}
    {renderLoginModal()}
    {renderModal()}

    <div className="mw-main-layout">
      {/* Sidebar for user profile and pathway */}
      <aside className="sidebar-left">
      <img className='logo' src="https://i.ibb.co/MsZrszj/logothick.png"/>
      <button className="newGame" onClick={handleNewGameClick}>New Game</button>

      
      {/* Conditionally render the new game options */}
      {showOptions && (
        <div className='newGameOptions'>
          <button onClick={() => fetchTargets(1)}>1 Target</button>
          <button onClick={() => fetchTargets(3)}>3 Targets</button>
          <button onClick={() => fetchTargets(5)}>5 Targets</button>
          <button onClick={resetTargets}>Daily Hunt</button>
        </div>
      )}

{isLoggedIn ? (
            <>
            <div className='userProfile'>{username} 
            {isLoggedIn && (
        <button onClick={handleLogout} className="logout-button">
          Logout
        </button>
      )}

            </div>
          </>
          ) : (
            <></>
          )}
	  
    <h5 className='tableTitle'>Leaderboard</h5>
        <table>
        
          <thead>
            <tr>
            
            </tr>
          </thead>
          <tbody>
            {leaderboard.map((user) => (
              <tr key={user._id}>
                <td>{user.username}</td>
                <td>{user.clicks}</td>
              </tr>
            ))}
          </tbody>
        </table> 

        {gameMode !== '' ? (
      <button className="abortGame" onClick={handleAbortGameClick}>Abort Game</button>
      ):(
        <></>
      )
    }
      </aside>

      {/* Main content area */}
      <div className="mw-main-content">
        
        <section className="main-content">
          {!isLoggedIn && !isAnonymous && gameMode === '' ? (
		   <center>
        <div className='landingPageContainer'>
          <div className='homeContainer'>
          <div className="homeDailyHunt">
          <img className='logo' src="https://i.ibb.co/MsZrszj/logothick.png" />
          <h1>Daily Hunt: <Timer />
          </h1>
          
        {dailyHunt.map((target, index) => (
  <div key={index}>
    <ArticleImage target={target} />
    <div className='dailyHuntTitle'>
      {index === 0 ? '🟢' : '📍'} {target.replace(/_/g, ' ')}
    </div>
  </div>
))}
<div className='ctaContainer'>
<button onClick={() => resetTargets()}>Start Game <span>(as Guest)</span></button>
</div>
          </div>
          </div>
          <div className="vl"></div>
      <div className="form-container">
			 
    <Login onLogin={(token, username) => {
      setIsLoggedIn(true);
      setToken(token);
      setUsername(username);
      localStorage.setItem('authToken', token);
    }} />
    <a className="play-anonymously" onClick={() => setIsAnonymous(true)}></a>
	</div>
  </div>
  </center>
          ) : (
            <></>
          )}

          {/* Sidebar for leaderboard */}
      {gameMode === '' && isLoggedIn ? (
      <div className='homeContainer'>
        <div className="homeDailyHunt">
        <img className='logo' src="https://i.ibb.co/MsZrszj/logothick.png" />
        <h1>Daily Hunt: <Timer />
          </h1>
          
        {dailyHunt.map((target, index) => (
  <div key={index}>
    <ArticleImage target={target} />
    <div className='dailyHuntTitle'>
      {index === 0 ? '🟢' : '📍'} {target.replace(/_/g, ' ')}
    </div>
  </div>
))}
<div className='ctaContainer'>
<button onClick={() => resetTargets()}>Start Game</button>
</div>
          </div>
          <div className='vl'></div>
        <div className="playOptions">
          <h1>Start Hunting!</h1>
          <center>
          <button onClick={() => fetchTargets(1)}><span className='icon'>🎯</span><div className='desc'><h1>1 Target </h1> <span>Classic Wiki Race</span></div></button>
          <button onClick={() => fetchTargets(3)}><span className='icon'>🥉</span><div className='desc'><h1>3 Targets </h1> <span>Classic Wiki Hunt</span></div></button>
          <button onClick={() => fetchTargets(5)}><span className='icon'>🌟</span><div className='desc'><h1>5 Targets </h1><span>Find All Five!</span></div></button>
          <button onClick={() => fetchTargets(10)}><span className='icon'>🔥</span><div className='desc'><h1>10 Targets </h1><span>Ultimate challenge!!!</span></div></button>

          <button onClick={resetTargets}><span className='icon'>📅</span><div className='desc'><h1>Daily Hunt </h1><span>New Challenge Daily!</span></div></button>

          <button className='underDev'><span className='icon'>🤝</span><div className='desc'><h1>Co-op </h1><span>Coming soon!</span></div></button>
          <button className='underDev'><span className='icon'>⚔️</span><div className='desc'><h1>1v1 </h1><span>Coming soon!</span></div></button>
          <button className='underDev'><span className='icon'>⏳</span><div className='desc'><h1>Timed Challenge </h1><span>Coming soon!</span></div></button>
          <button className='underDev'><span className='icon'>🏆</span><div className='desc'><h1>Tournament </h1><span>Coming soon!</span></div></button>

          </center>
        </div>
        <div className='moentization'>

        </div>
      </div>
      ):(

        <>
              {gameMode !== '' ? (
              <div
                onClick={handleLinkClick}
                dangerouslySetInnerHTML={{ __html: article.htmlContent || '' }}
                className="mw-parser-output"
              />
              ):(
                <></>
              )}
            </>

      )}
        </section>
      </div>
    
      {/* Sidebar for leaderboard */}
      {gameMode === '' ? (
      <div></div>):(
        <aside className="sidebar-right">
      <p>Click Count: {clickCount}</p>
      <a className='mobileAbort' onClick={handleAbortGameClick}>Give Up</a>
	  <h3>Targets</h3>
	  <ul className="target-list">
		{targets.map((target, index) => (
		  <li key={index} className={`targets ${completedTargets.includes(target) ? 'completed' : ''}`}>
			📍 {target.replace(/_/g, ' ')}
		  </li>
		))}
	  </ul>
    <section className="pathway">
          <h3>Trail</h3>
          <ol>
            {pathway.map((page, index) => (
              <li key={index}>{page}</li>
            ))}
          </ol>
        </section>
        
      </aside>
      )}
    </div>
  </div>
);


};

export default App;
